export async function useInfosWithTitleItem(deliveryKey: string | Ref<string>) {
  const localePath = useLocalePath()
  const key = toRef(() => deliveryKey)

  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey: key.value,
  }

  return useFetch('/api/cms/getInfosWithTitleItem', {
    query,
    immediate: false,
    watch: isRef(deliveryKey) ? [key] : false,
  })
}
